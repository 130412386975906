
.activeNavElement {
  text-decoration: none;
  text-transform: uppercase;
  background: tomato;
  border-radius: 10px;
}

/* hide when scroll up */
header.hidden {
  transform: translateY(-100%);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  height: var(--navheight);
  background: var(--background);  
  box-shadow: var(--boxshadowwithopacity);
}

.nav_bar{
  max-width: 1440px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: inherit;  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_bar .logo_image {
  height: inherit;
  width: clamp(150px, 2vw, 175px);
}

.nav_bar nav {
  display: flex;  
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: inherit;
  transition: all 0.3s ease-in-out;
  background: var(--background);
  list-style: none;
  gap: 1em;
}

.nav_bar nav a {
  color: var(--text);
  font-weight: 500;
  display: block; 
  padding: 10px;  
}

.nav_bar .about-menu {
  display: block;
  max-height: 0;
  position: absolute;
  overflow: hidden;
  top: var(--navheight);
  transition: max-height 0.4s ease-in-out;
  background-color: var(--background); 
  box-shadow: var(--boxshadowwithopacity);
}

.nav_bar .about-menu a {
  border-bottom: solid var(--backgroundColor) 1px;
}

.nav_bar nav .navRego {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.nav_bar nav #loggedIn,
.nav_bar nav #loggedOut,
.nav_bar nav #reg {
  background-color: var(--background);
  color: var(--textColor);
  border:none;
  border-radius: 20px;
  padding: 0px 10px;
}

.nav_bar nav #loggedIn:hover,
.nav_bar nav #loggedOut:hover,
.nav_bar nav #reg:hover,
.toggle-btn:hover{
  background-color: var(--hoverColor);
}

.toggle-btn {
  cursor: pointer; 
  border: none;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  outline: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 0.7rem;
  transition: 0.3s;
}

.toggle-btn i {
  line-height: 25px;
}

.main-wrapper.light .toggle-btn i:last-child,
.main-wrapper.dark .toggle-btn i:first-child {
  display: none;
}

.main-wrapper.light .toggle-btn i:first-child,
.main-wrapper.dark .toggle-btn i:last-child {
  display: block;
}

@media screen and (max-width: 768px) {

  .nav_bar {
    top: 0;
    width: 100%;
  }
  
  .nav_bar .logo_image {
    flex: 1;
    margin: auto;
    height: var(--navheight);
  }

.nav_bar nav {
  display: none;
}

.nav_bar.active nav {
  display: block;
  position: absolute;   
  height: auto;
  top: var(--navheight);  
  padding: 20px; 
  width: 100%;
  transition: all 0.3s ease-in-out ;
}

.nav_bar nav a {
  border-bottom: solid var(--backgroundColor) 1px;
}

.nav_bar nav .about-menu {
  max-height: 0;
  top: 0;
  position: relative;  
  overflow: hidden;    
}

.nav_bar .about-menu a {
  width: 80%;
  margin: 0 auto;
  color: var(--background);
  background-color: var(--backgroundColor);
  border-bottom: solid var(--background) 1px;
  box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.2),
              inset -5px -5px 15px rgba(255, 255, 255, 0.5);
}

.nav_bar nav .navAbout {
  display: flex;
  align-items: center;
}

.nav_bar nav .navAbout .toggle-icon {
  margin-left: auto;
}

.nav_bar nav .navRego {
  margin: 40px 0 20px 0;
}

  .nav_bar .hamburger-menu {
    flex: 1;    
    cursor: pointer; 
    padding: 0 10px 0 0px;
  }

  .nav_bar .hamburger-menu .bar {
  position: relative;
  margin-left: auto;
  height: 3px;
  width: 30px;
  background-color: var(--backgroundColor);
  border-radius: 3px;
}

.hamburger-menu .bar::before,
.hamburger-menu .bar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor);
  transition: 0.5s;
}

.hamburger-menu .bar::before {
  transform: translateY(-8px);
}

.hamburger-menu .bar::after {
  transform: translateY(8px);
}

.nav_bar.active .hamburger-menu .bar {
  background-color: transparent;
}

.nav_bar.active .bar::before {
  transform: translateY(0) rotate(-45deg);
}

.nav_bar.active .bar::after {
  transform: translateY(0) rotate(45deg);
}

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--backgroundColor);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }

  .nav_bar.active .overlay {
    pointer-events: all;
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {

}