
/* Footer */
.footer {  
  min-height: 50vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
 }
 
 .footer .footer-container {
   display: flex;
   justify-content: space-around;
   align-items: center;
   width: 100%; 
 }
 
 /* background image opacity */
 .footer-container::before {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background-image: url("../img/shape1.svg"); */
   background-repeat: no-repeat;
   background-size: contain;  
   background-position: center;
   opacity: 0.1;
   z-index: -1;
 }
 

.footer .social-icons {
  display: inline-flex;
    gap: 2rem;
    color: red;
}

 .footer .footer-container .cell {
   display: flex;
   flex-direction: column;
 }
 
 .footer .footer-container .cell:nth-child(1){
 align-items: center;
 justify-content: center;
 text-align: center;
 }

 .footer .footer-container .cell:nth-child(1) > div {
  display: inline-flex;
  gap: 20px;
 }
 
 .footer .footer-container .cell:nth-child(1) img {
   width: 250px;
   height: 80px;
 }
 
 .footer .footer-container .cell a {
   margin-bottom: 0.6rem;  
 }
 
 .footer .footer-container .cell,
 .footer .footer-container .cell a,
 .footer .footer-container .cell p,
 .footer .footer-container .cell i {
   color: var(--textColor);
   font-size: calc(var(--textSize) - 2px);
 }

 .footer .cell p {
  text-align: left;
 }
 
 .footer p {
   text-align: center;
 }
 
 @media screen and (max-width: 768px) {
 
   .footer .footer-container {
     flex-direction: column;    
     align-items: flex-start;
   }
 
 }
 