
 .quoteSection {
  padding: calc(var(--navheight)/2) 5px;
    max-width: 1000px;
    width: 100%;
    margin: auto 
    }
  
 .quoteSection h1 {
    margin-bottom: 20px;
    text-align: center;
    color: var(--text);
  }
  
 .quoteSection p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .quote-form-container {
    position: relative;
    background-color: var(--background);        
    padding: 10px;
    border-radius: 5px;
    width: 50vw;
    margin: auto;
    box-shadow: var(--boxshadowwithopacity);
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--backgroundColor);
    padding: 10px;
    border-radius: 5px;
    box-shadow: var(--boxshadowwithopacity);
  }

  .question-label {
    display: block;
    color: var(--textColor);
    margin-bottom: 0.5rem;
  }

  .form-container form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 1rem;
  }

  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 0.5rem;    
    border-radius: 4px;
    box-sizing: border-box;
  }

  .quote-form-container .next-button, .quote-form-container .previous-button,
  .quote-form-container .next-button, .quote-form-container .next-button{
    display: block;
    margin: 10px auto;
    padding: 5px;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  #submit-button, #verify-code-button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    background-color: tomato;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

 #submit-button:hover, #verify-code-button:hover{
  background-color: rgb(225, 97, 74);
}
  
  @media (max-width: 768px) {

 .quoteSection {
  padding: calc(var(--navheight)/4) 5px;
  }
    .signUpContainer {
      width: 80%;
    }

    .quote-form-container {
      width: 70vw;
    }

    .quote-form-container button {
      padding: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .signUpContainer {
      width: 80%;
    }

    .quote-form-container {
      width: 100%;
    }
  }
  